import React, { useEffect, useState } from "react";
import * as S from "../Filter.style";
import PaginationComponent from "../pagenation/PagenationComponent";
import ListComponent from "../pagenation/List";
import { client } from "../../../client";
import { GET_ALL_DOCUTYPE, GET_FILTER_DATA } from "./gql/document.gql";

const ITEMS_PER_PAGE = 10;
const MAX_PAGES = 5;

const MockTests = () => {
  const [documentData, setDocumentData] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const headers = [
    "번호",
    "학년",
    "년도",
    "월",
    "자료",
    "업로드일",
    "다운로드수",
    "가격",
    "파일",
  ];
  const columnRatios = "0.2fr 0.2fr 0.3fr 0.2fr 0.8fr 0.3fr 0.25fr 0.2fr 0.1fr";

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSet, setPageSet] = useState(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;

  const [grades, setGrades] = useState([]);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isDownloaded, setIsDownLoaded] = useState(false);

  const getFilter = () => {
    setIsLoading(true);
    client
      .query({
        query: GET_FILTER_DATA,
        variables: {
          docu_type1_id: 1,
        },
      })
      .then((res) => {
        const fetchedData = JSON.parse(res.data.getFilterListByDocumentType1);
        const gradeData = fetchedData.filterList.find(
          (f) => f.col_name === "grade"
        ).value;
        setGrades(gradeData);

        const monthData = fetchedData.filterList.find(
          (f) => f.col_name === "month"
        ).value;
        setMonths(monthData);

        const yearData = fetchedData.filterList.find(
          (f) => f.col_name === "year"
        ).value;
        setYears(yearData.map((item) => item.toString()));

        const documentTypeData = fetchedData.filterList.find(
          (f) => f.col_name === "docu_type2"
        ).value;
        setDocumentTypes(documentTypeData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  };

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      const res = await client.query({
        query: GET_ALL_DOCUTYPE,
        context: {
          headers: {
            authorization: accessToken ? `Bearer ${accessToken}` : "",
          },
        },
        variables: {
          document_search_input: {
            docu_type1_id: 1,
            docu_type2_id: selectedDocumentTypes.map((item) => item.id),
            grade: selectedGrades,
            year: selectedYears.map((item) => parseInt(item)),
            month_id: selectedMonths.map((item) => item.id),
            name: searchTerm === "" ? null : searchTerm,
            page: 1,
            pageSize: 200,
            is_old: false,
          },
        },
        fetchPolicy: "no-cache",
      });

      const documents = res.data.searchDocuTypeV2.map(async (item, index) => {
        const {
          id,
          grade,
          year,
          docu_type1_name,
          docu_type2_name,
          month_id,
          name,
          created_at,
          downloaded,
          price,
          documentUrlList,
        } = item;
        const date = new Date(parseInt(created_at, 10));
        const formattedDate = date.toISOString().split("T")[0];

        const urls = item.documentUrlList;

        let hwpUrl;
        let pdfUrl;

        urls.map((item) => {
          item.type === "HWP"
            ? (hwpUrl = item.object_url)
            : (pdfUrl = item.object_url);
        });

        return {
          index: id,
          grade,
          year,
          docu_type1_name,
          docu_type2_name,
          month: months.find((m) => m.id == month_id)?.name,
          name,
          uploadedDate: formattedDate,
          downloaded,
          price,
          downloadUrl: {
            pdfUrl,
            hwpUrl,
            id,
            price,
          },
        };
      });

      Promise.all(documents).then(setDocumentData).catch(console.error);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getFilter();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const currentItems = documentData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSearch = (event) => {
    event.preventDefault();
    fetchData();
  };

  const handleCheckboxChange =
    (setSelected, items, isObject = false) =>
    (event) => {
      const { value } = event.target;
      if (isObject) {
        //objects with id and name
        const selectedItem = items.find((item) => item.name === value);
        setSelected((prev) =>
          prev.some((item) => item.name === value)
            ? prev.filter((item) => item.name !== value)
            : [...prev, selectedItem]
        );
      } else {
        //simple strings
        setSelected((prev) =>
          prev.includes(value)
            ? prev.filter((item) => item !== value)
            : [...prev, value]
        );
      }
    };

  if (isLoading) {
    return <div>데이터 로딩 중...</div>;
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.FilterContainer>
          <S.FilterSection>
            <S.FilterTitle>학년</S.FilterTitle>
            {grades.map((grade) => (
              <S.FilterCheckboxLabel key={grade}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={grade}
                  checked={selectedGrades.includes(grade)}
                  onChange={handleCheckboxChange(setSelectedGrades, grades)}
                />
                {grade}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
          <S.FilterSection>
            <S.FilterTitle>년도</S.FilterTitle>
            {years.map((year) => (
              <S.FilterCheckboxLabel key={year}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={year}
                  checked={selectedYears.includes(year)}
                  onChange={handleCheckboxChange(setSelectedYears, years)}
                />
                {year}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
          <S.FilterSection>
            <S.FilterTitle>월</S.FilterTitle>
            {months.map((month) => (
              <S.FilterCheckboxLabel key={month.id}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={month.name}
                  checked={selectedMonths.some(
                    (selectedMonth) => selectedMonth.id === month.id
                  )}
                  onChange={handleCheckboxChange(
                    setSelectedMonths,
                    months,
                    true
                  )}
                />
                {month.name}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
          <S.FilterSection>
            <S.FilterTitle>자료</S.FilterTitle>
            {documentTypes.map((documentType) => (
              <S.FilterCheckboxLabel key={documentType.id}>
                <S.FilterCheckbox
                  type="checkbox"
                  value={documentType.name}
                  checked={selectedDocumentTypes.some(
                    (selectedDocuType) =>
                      selectedDocuType.id === documentType.id
                  )}
                  onChange={handleCheckboxChange(
                    setSelectedDocumentTypes,
                    documentTypes,
                    true
                  )}
                />
                {documentType.name}
              </S.FilterCheckboxLabel>
            ))}
          </S.FilterSection>
        </S.FilterContainer>
        <S.SearchContainer as="form" onSubmit={handleSearch}>
          <S.Input
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <S.SearchButton type="submit">검색</S.SearchButton>
        </S.SearchContainer>
      </S.Container>
      <ListComponent
        headers={headers}
        columnRatios={columnRatios}
        items={currentItems}
        fetchData={fetchData}
      />
      <PaginationComponent
        data={documentData}
        currentPage={currentPage}
        setPage={setCurrentPage}
        pageSet={pageSet}
        setPageSet={setPageSet}
        itemsPerPage={ITEMS_PER_PAGE}
        maxPages={MAX_PAGES}
      />
    </S.Wrapper>
  );
};

export default MockTests;
